*,
input,
select,
textarea {
  font-size: 14px;
  font-family: "PingARLT", Arial, Helvetica, sans-serif;
}

.form-select {
  background-position: left 0.5rem center;
}

/* For those who use it direct */
select {
  &.small {
    min-height: 20px;
  }
  min-height: 36px;
  background-position: left 0.5rem center;
  background-size: 1.2em 1.2em;
  box-shadow: none !important;
}

input,
select,
textarea {
  font-size: 14px !important;
  font-family: "PingARLT", Arial, Helvetica, sans-serif;
}

input, textarea {
  font-size: $text-x-sm !important;
  text-align: #{$right};
  border-color: $color-gray-200 !important;

  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

input:not([type="radio"]):not([type="checkbox"]):not([type="color"]):not([type="range"]) {
  min-height: $input-height;
  padding: 5px 8px 7px 12px;
}

input[type="color"] {
  width: 30px;
  height: 30px;
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

label {
  font-size: $text-x-sm !important;
  margin-bottom: 8px;
}

.label-description {
  font-size: 12px;
}

.form--tooltip {
  & > div {
    z-index: 1000;
    max-width: 12rem;
    padding: 0.7;
  }
}

.form-row {
  display: flex;
  @media screen and (max-width: 768px) {
    display: block;
  }
  & > label:first-of-type {
    flex: 0 0 40%;
  }
  & > div:first-of-type {
    flex: 0 0 60%;
  }
}

.input-wrapper {
  display: table;
  & > input {
    border: 1px solid;
  }
  > * {
    display: table-cell;
    height: 100%;

    &:first-child {
      width: 1%;
      font-size: $text-x-sm;
      color: $color-gray-400;
      border: 1px solid $color-gray-200;
      padding: 5px 10px;
      border-#{$left}: none;
      @if ($direction == 'rtl') {
        border-radius: 0 $b-radius--min $b-radius--min 0 !important;
      } @else {
        border-radius: $b-radius--min 0 0 $b-radius--min !important;
      }
    }

    &:nth-child(2) {
      border-#{$right}: none !important;
      padding-#{$right}: 0 !important;
      @if ($direction == 'rtl') {
        border-radius: $b-radius--min 0 0 $b-radius--min !important;
      } @else {
        border-radius: 0 $b-radius--min $b-radius--min 0 !important;
      }
      
      input,
      textarea,
      .dropdown-toggle {
        border-#{$right}: none !important;
        padding-#{$right}: 0 !important;
        /* for multi-input-wrapper */
        @if ($direction == 'rtl') {
          border-radius: $b-radius--min 0 0 $b-radius--min !important;
        } @else {
          border-radius: 0 $b-radius--min $b-radius--min 0 !important;
        }
      }
    }

  }
}

.multilanguage-wrapper {
  & .form--lang-options {
    position: absolute;
    top: 4px;
    left: 0;
  }
}

.dropdown-items, .language-dropdown {
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: #f8f8f8;
  }
  &::-webkit-scrollbar-track {
    border-radius: $b-radius;
    background-color: #f8f8f8;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $b-radius;
    background-color: #c1c1c1;
  }

}

.has-error {
  textarea,
  input:not([type="radio"]):not([type="checkbox"]):not([type="color"]):not([type="range"]) {
    color: $color-danger !important;
    border-color: $color-danger !important;
  }
  .quill-wrapper, .codemirror-container, .img-uploader {
    border-color: $color-danger !important;
    border-width: 1px;
  }
  .input-wrapper {
    .icon-wrapper {
      color: $color-danger;
      border-color: $color-danger !important;
    }
  }
  .multilanguage-wrapper .contents > div {
    border-color: $color-danger !important;
    color: $color-danger;
  }
  .dropdown-toggle {
    color: $color-danger;
    border-color: $color-danger !important;
  }
}

.form--element-email, .form--element-icon {
  display: inline-flex !important;
  width: 50%;
  & label {
    display: none !important;
  }
}


/* inline field */
.salla-form-builder-vertical .form--string-icon.form--inline {
  & .dropdown-toggle {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0 !important;
  }

  &:has(+ div[class*="form--string-textarea"]) .dropdown-toggle {
    border: 0 !important;
    position: absolute !important;
    z-index: 50;
  }

  & + div[class*="form--string-textarea"] {
    & .input-container { margin-top: -28px !important; }
    & textarea { padding-right: 60px !important; }
  }

  & + div[class*="form--"]:not(div[class*="form--string-textarea"]) {
    position: absolute;
    padding: 0 !important;
    transform: translateY(-38px) translateX(-98px);
    width: calc(100% - 159px);
    z-index: 50;
  }
  
  & + div[class*="form--"]:not(div[class*="form--string-textarea"]) .input-wrapper .icon-wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

/* inline field - end */

@import "img-uploader";
@import "toggle-wrapper";
@import "dropdown";
@import "range";
@import "datepicker";
@import "color";