.dropdown {
  &-wrapper {


  }

  &-toggle {
    min-height: $input-height;
    border: 1px solid $color-gray-200 !important;

    > * {
      min-height: $input-height;
      max-height: $input-height;
    }
  }

  &-items {

  }

  &-item {
    // max-height: 36px;
  }

  &-chip {
    font-size: $text-x-sm !important;
    [class^="sicon"] {
      font-size: $text-x-sm;
    }
  }

  &-selected {
    color: $color-primary-l !important;
    background-color: rgba($color-secondary, 0.2) !important;
    &:hover {
      background-color: rgba($color-secondary, 0.35) !important;
    }
  }
}


.form--items-dropdown-list .dropdown-items {
  max-height: 175px !important;
}

.dropdown-toggle > div > div.flex-auto {
  display: inline-table !important;
}

.dropdown-toggle .cursor-pointer {
  overflow: hidden;
}

.dropdown-chip {
  display: inline-block !important;
  max-width: 80px !important;
  position: relative;
  overflow: hidden;
  padding: 4px 5px 9px 20px !important;
  font-size: 12px !important;
  line-height: 1 !important;
  text-overflow: ellipsis;
  margin-left: 2px !important;
}

.dropdown-chip > div {
  position: absolute;
  top: 5px;
  left: 5px;
  color: red;
}

.language-dropdown {
  overflow-y: scroll;
  max-height: 125px;
}
