.modal {
	@apply relative z-50;

	&__backdrop {
		@apply fixed inset-0 bg-black/30;
	}

	&__body {
		@apply relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-screen sm:max-w-3xl;
	}

	&__container {
		@apply fixed inset-0 flex w-screen items-center justify-center p-4;
	}

	&__header {
		@apply bg-secondary-50 flex justify-between items-center px-5 py-4;
	}

	&__footer {
		@apply flex justify-between items-center bg-gray-50 py-3 px-4;
	}
}
