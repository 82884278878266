/* 
- @Adel
  This is now replace by components/Spinner.js, which is simpler implementation. kindly review
  This file shall be deleted if we will keep the new component
*/

.spinner {
  display: block;
  border-radius: 50%;
  animation: rotation 0.6s infinite linear;
  &.green {
    border: 4px solid #5cd5c53a;
    border-top-color: #5cd5c4;
  }
  &.light {
    border: 4px solid #ffffff3a;
    border-top-color: #ffffff;
  }
}


@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}