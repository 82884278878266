.checkbox {
  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    + label {
      padding-#{$right}: 25px;

      &:before, &:after {
        display: block;
        position: absolute;
        top: 2px;
        #{$right}: 0;
        @include transi();
      }

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        background-color: white;
        border: 1px solid $color-gray-300;
      }

      &:after {
        content: '';
        display: none;
      }
    }
  }

  input[type="checkbox"] {
    + label {
      &:before {
        @include b-radius($b-radius--min);
      }

      &:after {
        color: #004d5a;
        content: "\ea9d";
        font-family: sallaicons;
        font-weight: 600;
        font-size: 14px;
        height: 18px;
        outline: none;
        right: 0;
        text-align: center;
        top: 0;
        width: 18px;
      }
    }
    &:checked {
      + label {
        &:before {
          background-color: $color-white;
          border-color: $color-primary;
        }
        &:after {
          display: block;
        }
        &::marker {
          color: $color-primary;
        }
      }
    }
  }

  input[type="radio"] {
    + label {
      &:before {
        @include b-radius(50%);
      }
      &:after {
        width: 10px;
        height: 10px;
        top: 6px;
        #{$right}: 4px;
        @include b-radius(50%);
        background-color: $color-white;
      }
    }
    &:checked {
      + label {
        &:before {
          background-color: $color-white;
          border-color: $color-primary;
        }
        &:after {
          display: block;
          background-color: $color-primary;
        }
      }
    }
  }




  &--toggle {
    input[type="checkbox"] {

      + label {
        padding-#{$right}: 40px;

        &:before {
          width: 32px;
          height: 20px;
          @include b-radius(10px);
        }

        &:after {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          top: 3px;
          @include b-radius(50%);
          box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
          #{$right}: 13px;
          transform: unset;
        }
      }

      &:checked {
        + label {
          &:before {
            background-color: $color-secondary;
            border-color: $color-secondary;
          }

          &:after {
            right: 1px;
            background-color: $color-white;
          }
        }
      }
    }
  }
}
