@use "sass:math";

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }
  @return $number;
}

@function get-vw($target) {
  $vw-context: ($max-breakpoint * .01) * 1px;
  @return math.div($target, $vw-context) * 1vw;
}

@function get-rem($pxsize) {
  @return math.div($pxsize, strip-unit($base-size))+rem;
}

@function get-font($key) {
  @return map-get($fonts, $key);
}

@mixin b-radius($val) {
  border-radius: $val;
}

@mixin strip-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin fontAwesome5($icon: "") {
  content: $icon;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
}

@mixin transi($property:all, $time: $trans-speed, $ease: cubic-bezier(0.2,1,0.3,1)) {
  transition: $property $time $ease;
}

@mixin flexable($align: center, $justify: center, $direction: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

@mixin res-width($max, $min) {
  width: get-vw($max);
  max-width: $max;
  min-width: $min;
}

@mixin res-height($max, $min) {
  height: get-vw($max);
  max-height: $max;
  min-height: $min;
}

@mixin mediaMinWidth($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin mediaMaxWidth($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin mediaMinMaxWidth($minWidth,$maxWidth) {
  @media only screen and (min-width: $minWidth) and (max-width: $maxWidth) {
    @content;
  }
}

@mixin landScapeSmallPhones() {
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin landScapeLargePhones() {
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @content;
  }
}

@mixin scrollBar($barWidth, $barColor, $barThumb) {
  &::-webkit-scrollbar {
    width: $barWidth;
  }

  &::-webkit-scrollbar-track {
    background: rgba($barThumb, 0.8);
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background: $barColor;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba($barColor, 0.5);
  }
}

@mixin selection($frontColor, $bgColor) {
  &::selection {
    background-color: rgba($bgColor, 0.05);
    color: $frontColor;
  }

  &::-moz-selection {
    background-color: rgba($bgColor, 0.05);
    color: $frontColor;
  }
}

@mixin centerX() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerY() {
  top: 50%;
  transform: translateY(-50%);
}


@mixin centerXY() {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}


@mixin center-v {
  top: 50%;
  transform: translateY(-50%);
}

@mixin right-icon-el($margin) {
  display: inline-block;
  vertical-align: middle;
  margin-left: #{$margin}px;
}
