$colors: (
	1: #54ca9f,
	2: #106d71,
	3: #0886c9,
);

.template {
	@apply flex w-[793px] h-[1100.5px] flex-col justify-center items-center bg-center bg-cover bg-no-repeat relative;
	&__wrapper {
		@apply w-full h-full flex-1 pt-52;
	}
	&__header {
		@apply flex flex-col space-y-4 justify-center items-center px-10;
		&__logo {
			@apply w-[225px] h-[90px] object-contain;
		}
		&__image {
			@apply w-[225px] h-[90px] object-contain;
		}
	}
	&__content {
		@apply flex flex-col space-y-12 justify-center items-center py-8 mt-4 mb-8;
		&__heading {
			@apply flex flex-col space-y-4 justify-center items-center;
			.title {
				@apply text-template-color font-bold text-3xl;
			}
			.subtitle {
				@apply text-dark-100 font-medium text-2xl;
			}
			.customer {
				@apply text-template-color font-bold text-2xl;
			}
			.amount {
				@apply text-template-color font-bold text-2xl;
			}
		}
		&__main {
			@apply grid grid-cols-2 place-items-center gap-4;
		}
	}
	&__footer {
		@apply flex flex-col space-y-8 justify-center items-center;
		img {
			@apply w-[450px] h-[75px] object-contain;
		}
		.title {
			@apply text-template-color font-bold text-3xl text-center;
		}
		.subtitle {
			@apply text-dark-100 font-medium text-3xl max-w-2xl text-center;
		}
	}
}

// generate dynamic text color for templates
@each $number, $color in $colors {
	.template-#{$number} {
		.text-template-color {
			color: $color;
		}
	}
}

@media print {
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	@page {
		size: A4;
		margin: 0;
	}
	.no-print {
		display: none;
	}

	.print-only {
		display: block;
	}

	/* Add your print styles here */
	.min-h-screen {
		min-height: 100vh;
	}

	.min-w-screen {
		min-width: 100vw;
	}

	.bg-fixed {
		background-attachment: fixed;
	}

	.bg-center {
		background-position: center;
	}

	.bg-cover {
		background-size: cover;
	}
}
