.react-datepicker {
  $date-picker-root: &;

  &-popper {
    z-index: 20 !important;
    .react-datepicker {
      direction: ltr;
      border-color: #eee !important;
      border: 1px solid #ddd !important;
      box-shadow: 0 1px 3px rgb(0 0 0 / 10%) !important;
      margin: 0;
      @include b-radius($b-radius--min);
      padding: 10px;

      &__day,
      &__day-name {
        min-width: 30px;
        min-height: 30px;
        line-height: 1;
        outline: none !important;
        margin: 0;
        border: none;
        padding: 8px;
        border-radius: $b-radius--min !important;
        font-size: $text-x-sm;
        color: $color-dark-300;

        &:hover {
          outline: none !important;
          background: rgba($color-gray-200, 0.9) !important;
        }

        &--selected {
          color: $color-primary-l;
          background: $color-secondary-50 !important;
        }

        &--keyboard-selected {
          color: $color-dark-300;
          background: transparent;
        }
      }

      &__input-container {
        display: block !important;
      }

      &__navigation {
        width: 25px;
        height: 25px;
        top: 25px;
        margin: 2px;
      }

      &__month {
        margin: 0 !important;
      }

      &__current-month {
        font-size: $text-lg;
      }

      &__header {
        padding: 10px 0 20px;
        border: none;
        background-color: #fff !important;
        font-weight: 400 !important;
        &--time {
          .react-datepicker-time__header {
            font-size: $text-x-sm;
          }
        }
      }

      &__day-names {
        margin: 0;
      }

      &__day-name {
        color: $color-dark-100;

        &:hover {
          color: $color-dark-100 !important;
          background: transparent !important;
        }
      }

      &__month-container {
        border-color: #eee !important;
      }

      &__day--outside-month {
        color: #e0e0e0 !important;
        pointer-events: none !important;
      }

      &__current-month {
        font-weight: 400 !important;
      }

      &__time-container {
        border-color: #eee !important;
      }

      &__navigation-icon {
        &:before {
          border-width: 2px 2px 0 0 !important;
          width: 7px !important;
          height: 7px !important;
          border-color: #333;
        }
      }

      &__triangle {
        &:before, &:after {
          display: none; /*remove if needed*/
          left: -24px !important;
        }

        &:after {
          border-bottom-color: #fff !important;
        }
      }

      &__time-list {
        @include scrollBar(2px, $color-primary, $color-gray-300);
      }

      &__time-list-item {
        margin: 4px;
        font-size: $text-x-sm;
        color: $color-dark-300;
        @include b-radius($b-radius--min);
        &--selected {
          color: $color-primary-l !important;
          background: $color-secondary-50 !important;
        }
      }

      &--time-only {
        #{$date-picker-root} {
          &__header {
            padding: 0 0 10px !important;

            .react-datepicker-time__header {
              font-size: $text-x-sm !important;
            }
          }
        }
      }
    }
  }

  &-wrapper {
    display: block !important;
    direction: ltr;
  }
}