/* Salla Style -- . */
@import url('https://cdn.salla.network/fonts/pingarlt.css?v=0.2');
@import url('https://cdn.salla.network/fonts/sallaicons.css');

* {
  font-size: 14px;
}

@import "scss/paritals/vars";
@import "scss/paritals/mixins";
@import "scss/paritals/layout";

@import "scss/tailwind";
@import "scss/form";
@import "scss/codeeditor";
@import "scss/spinner";
@import "scss/quill-editor";
@import "scss/alertbox";

.bg-primary {
  background: $color-primary;
}

.border-primary {
  color: $color-primary;
  border-color: $color-primary !important;
  * {
    color: inherit;
  }
  // override tailwind --
  &.border {
    border-color: $color-primary !important;
  }
}

.border-secondary {
  color: $color-secondary;
  border-color: $color-secondary !important;
  * {
    color: inherit;
  }
  // override tailwind --
  &.border {
    border-color: $color-secondary !important;
  }
}

.bg-header {
  background-color: #fcfcfc;
}

.border {
  border-color: #eee;
}

.CodeMirror {
  text-align: left;
}

.CodeMirror-gutters {
  border-left-color: #35cbb4b9 !important;
}

.text-md {
  font-size: $text-md;
}

h1 { font-size: 28px; }
h2 { font-size: 25px; }
h3 { font-size: 23px; }
h4 { font-size: 21px; }
h5 { font-size: 19px; }
h6 { font-size: 17px; }
hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: $color-gray-200;
  height: 1px;
}

.z {
  &-language { z-index: 55 !important; }
  &-select { z-index: 60 !important; }
  &-popover { z-index: 70 !important; }
  &-iconpicker, &-datepicker { z-index: 70 !important; }
  &-tooltip { z-index: 80 !important; }
  &-alert { z-index: 90 !important; }
}

.font-inherit {
  font-size: inherit;
  font-weight: inherit;
}

.wal2-confirm{
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

button:where(.swal2-styled){
 box-shadow: none !important;
}