// Chrome ---
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range'] {
    overflow: hidden;
    width: 12px;
    -webkit-appearance: none;
    background-color: rgba($color-gray-200, 1);
    @include b-radius($b-radius);

    &::-webkit-slider-runnable-track {
      height: 12px;
      -webkit-appearance: none;
      color: $color-secondary;
      @include b-radius($b-radius);
    }

    &::-webkit-slider-thumb {
      width: 12px;
      -webkit-appearance: none;
      height: 12px;
      cursor: ew-resize;
      background: $color-secondary;
      outline: 2px solid #FFF;
      box-shadow: 0 2px 12px rgba(68,68,68,.25);
      @include b-radius($b-radius);

    }
  }
}



input[type="range"] {
  // FF ---
  &::-moz-range-progress {
    background-color: $color-secondary;
  }

  &::-moz-range-track {
    background-color: rgba($color-secondary, 0.2);
  }

  // IE ---
  &::-ms-fill-lower {
    background-color: rgba($color-secondary, 0.2);
  }

  &::-ms-fill-upper {
    background-color: rgba($color-secondary, 0.2);
  }
}
