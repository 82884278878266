.panel {
	@apply border rounded-t-md my-4;

	&__header {
		@apply bg-gray-50 flex justify-between items-center px-4 py-5 text-dark-200;
	}

	&__title {
		@apply text-xl;
	}

	&__button {
		@apply outline outline-1 outline-secondary px-2 py-1 text-sm;
	}

	&__body {
		@apply transition-all duration-300 ease-in-out;
	}
}
