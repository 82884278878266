.alert-box {
  border: 1px solid;
  border-radius: 4px;
  padding: 10px 13px;

  & > i {
    font-size: 25px;
  }

  &--danger {
    border-color: rgba($color-danger, .2);
    background-color: rgba($color-danger, 0.1);
    color: darken($color-danger, 35%) !important;

    * {
      color: darken($color-danger, 35%) !important;
    }

    a {
      color: $color-danger !important;
    }

    .btn {
      &-action {
        color: white !important;
        background: darken($color-danger, 35%);
      }
    }
  }

  &--info {
    border-color: rgba($color-info, .2);
    background-color: rgba($color-info, 0.1);
    color: darken($color-info, 35%) !important;

    * {
      color: darken($color-info, 35%) !important;
    }

    a {
      color: $color-info !important;
    }

    .btn {
      &-action {
        color: white !important;
        background: darken($color-info, 35%);
      }
    }
  }

  &--warning {
    border-color: rgba($color-warning, .2);
    background-color: rgba($color-warning, 0.1);
    color: darken($color-warning, 35%) !important;

    * {
      color: darken($color-warning, 35%) !important;
    }

    a {
      color: $color-warning !important;
    }

    .btn {
      &-action {
        color: white !important;
        background: darken($color-warning, 35%);
      }
    }
  }
}