.img-uploader {
  background-color: #f8fafc;
  border: 2px dashed #e5e7eb;
  border-radius: 3px;
}

.img-uploader .sicon-image {
  font-size: 25px !important;
  opacity: .2;
}

.img-uploader label {
  height: 95px !important;
  cursor: pointer;
}