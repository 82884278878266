.react-select {
	&__control {
		@apply border-gray-200 #{!important};
		&--is-focused {
			@apply border-secondary-25 outline-secondary-25 #{!important};
		}
	}

	&__multi-value {
		@apply mx-1 py-0.5 px-1 text-primary-l rounded bg-secondary-25 #{!important};
		&__remove {
			@apply text-primary-l #{!important};
			&:hover {
				@apply bg-secondary-50 #{!important};
			}
		}
	}

	&__dropdown-indicator {
		svg {
			@apply fill-dark-300 w-[17px] h-[17px] #{!important};
		}
	}
}
