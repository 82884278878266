.CodeMirror-vscrollbar, .CodeMirror-hscrollbar {
  &::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar
  {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #76E8CD;
  }
}

.CodeMirror  {
  width: 0;
  flex-grow: 1;
}

.CodeMirror-scrollbar-filler {
  display: none !important;
}
