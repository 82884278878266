.ql-editor {
  direction: rtl !important;
  text-align: right !important;
  min-height: 120px;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto !important;
  left: 0;
}

.ql-snow {
  &.ql-toolbar,
  &.ql-container {
    border-color: $color-gray-200 !important;
  }
}
